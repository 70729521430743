import React from 'react';
import './index.scss';

const Loader = () => {
  const spinnerElements = Array.from({ length: 12 }, (_, index) => (
    <div key={index}></div>
  ));

  return (
    <div className='rate-loader-container'>
      <div className='lds-spinner'>{spinnerElements}</div>
      <div className='loading-text'>Rate is loading</div>
    </div>
  );
};

export default Loader;
