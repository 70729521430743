import { useState } from 'react';
import { Col, Container, Row, Form, Button, Toast } from 'react-bootstrap';
import FormInput from '../../../../components/formInput';
import './form.scss';
import { formatContactEmail } from '../../../../utils/helpers';
import { sendContactEmail } from '../../../../services/apis';
import {
  contactResponseMessages,
  contactUsFormInitialState,
  email,
} from '../../../../utils/constants';

const ContactUsForm = () => {
  const [validated, setValidated] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [formState, setFormState] = useState(contactUsFormInitialState);
  const [toast, setToast] = useState({
    show: false,
    message: '',
  });

  const handleChange = (value, name) => {
    setFormState((st) => ({ ...st, [name]: value }));
  };

  const showSnackbar = (messgae, show = true) => {
    setToast({ show, message: messgae });
  };

  const _handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    setSendEmailLoading(true);
    const data = {
      to: [email],
      subject: 'Website Contact Us Form Submitted',
      content: formatContactEmail(formState),
    };
    try {
      const response = await sendContactEmail(data);
      if (response && response.ok) {
        setFormState(contactUsFormInitialState);
        showSnackbar(contactResponseMessages.contactFormSuccess);
      } else {
        showSnackbar(contactResponseMessages.contactFormFailure);
      }
    } catch (err) {
      showSnackbar(contactResponseMessages.contactFormFailure);
    } finally {
      setSendEmailLoading(false);
    }
  };

  return (
    <div>
      <div className='form-container'>
        <div className='form-inner-container'>
          <Form onSubmit={_handleFormSubmit} validated={validated} noValidate>
            <Container>
              <Row className='justify-content-md-center'>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'firstName'}
                    label='First Name'
                    required
                    value={formState.firstName}
                    handleChange={handleChange}
                    errorMessage={'Please enter the first name'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'lastName'}
                    label='Last Name'
                    required
                    value={formState.lastName}
                    handleChange={handleChange}
                    errorMessage={'Please enter the last name'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'emailAddress'}
                    type={'email'}
                    label='Email Address'
                    handleChange={handleChange}
                    required
                    value={formState.emailAddress}
                    errorMessage={
                      formState.emailAddress
                        ? 'Please enter a valid email address'
                        : 'Please enter an email address'
                    }
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'phone'}
                    label='Phone'
                    required
                    value={formState.phone}
                    handleChange={handleChange}
                    errorMessage={'Please enter the phone'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'state'}
                    label='State'
                    value={formState.state}
                    handleChange={handleChange}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'city'}
                    label='City'
                    value={formState.city}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'zip'}
                    label='Zip Code'
                    value={formState.zip}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <FormInput
                    name={'description'}
                    label='Description'
                    value={formState.description}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <div className='button-container '>
                <Button
                  disabled={sendEmailLoading}
                  type='submit'
                  id='contact-form-button'
                >
                  {sendEmailLoading ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </Container>
          </Form>
        </div>
      </div>
      <div className='toast-parent'>
        <Toast
          onClose={() => setToast({ show: false, message: '' })}
          show={toast.show}
          delay={3000}
          autohide
        >
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default ContactUsForm;
