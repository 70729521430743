import flatbed from './icons/equipmentType/flatbed.svg';
import powerOnly from './icons/equipmentType/powerOnly.svg';
import rgn from './icons/equipmentType/rgn.svg';
import downArrow from './icons/downArrow.svg';
import backgroundTruckImage from './truckImages/backgroundTruckImage.svg';
import dryVanMountains from './truckImages/dryVanMountains.svg';
import flatbeds from './truckImages/flatbeds.svg';
import fleet from './truckImages/fleet.svg';
import forkLiftingCargoTruck from './truckImages/forkLiftingCargoTruck.svg';
import multipleReefers from './truckImages/multipleReefers.svg';
import excavatorOnConstructionSiteLg from './truckImages/excavatorOnConstructionSiteLg.svg';
import reeferInMountains from './truckImages/reeferInMountains.svg';
import reeferInMountainsMd from './truckImages/reeferInMountainsMd.svg';
import truckSide from './truckImages/truckside.svg';
import logo from './logo.png';
import teamImage from './team.svg';

const images = {
  logo,
  flatbed,
  powerOnly,
  rgn,
  backgroundTruckImage,
  dryVanMountains,
  flatbeds,
  truckSide,
  fleet,
  forkLiftingCargoTruck,
  multipleReefers,
  reeferInMountains,
  reeferInMountainsMd,
  excavatorOnConstructionSiteLg,
  teamImage,
  downArrow,
};

export default images;
