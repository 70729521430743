import Flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import './styles.scss';
import { useState } from 'react';
import { Form } from 'react-bootstrap';

const DatePickerField = ({
  name,
  label,
  placeholder,
  value,
  mode,
  onChange,
  onClose,
  minDate,
}) => {
  const [picker, setPicker] = useState('');

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const initFlatpickr = (ref) => {
    if (!picker && ref) {
      const newPicker = Flatpickr(ref, {
        mode: mode,
        dateFormat: 'Y-m-d',
        defaultDate: value,
        minDate,
        onChange: (selectedDates) => {
          const formattedDates = selectedDates.map(formatDate).join(' to ');
          onChange(formattedDates);
        },
        onClose: (selectedDates, dateStr, instance) => {
          if (selectedDates.length === 1) {
            instance.setDate([selectedDates[0], selectedDates[0]], true);
            onChange(formatDate(selectedDates[0]));
          }
        },
      });
      setPicker(newPicker);
    }
  };
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type='text'
        placeholder={placeholder}
        name={name}
        ref={initFlatpickr}
        value={value}
        onChange={onChange}
        onClose={onClose}
      />
    </Form.Group>
  );
};

export default DatePickerField;
