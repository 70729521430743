import images from '../../assets/images';

const BgGradientContainer = ({ style, children }) => {
  const sectionStyle = {
    backgroundImage: `linear-gradient(262deg, rgb(52 95 138) -32.74%, rgb(14 25 36 / 94%) 46.59%), url(${images.backgroundTruckImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '2% 0px 5% 0px',
  };
  return <div style={{ ...sectionStyle, ...style }}>{children}</div>;
};
export default BgGradientContainer;
