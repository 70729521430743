import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Toast } from 'react-bootstrap';
import './driverForUsForm.scss';
import { sendDriverEmail } from '../../../../services/apis';
import { formatDriverEmail } from '../../../../utils/helpers';
import FormInput from '../../../../components/formInput/index';
import {
  driverFormResponseMessages,
  email,
  strings,
} from '../../../../utils/constants';

const DriveForUsForm = () => {
  const [validated, setValidated] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    positionOfInterest: '',
    message: '',
  });
  const [toast, setToast] = useState({
    show: false,
    message: '',
  });

  const handleChange = (value, name) => {
    setFormState((st) => ({ ...st, [name]: value }));
  };
  const showSnackbar = (message, show = true) => {
    setToast({ show, message: message });
  };

  const handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }
    event.preventDefault();
    setSendEmailLoading(true);
    const data = {
      to: [email],
      subject: 'Website Join Our Team Form Submitted',
      content: formatDriverEmail(formState),
    };
    try {
      const response = await sendDriverEmail(data);
      if (response && response.ok) {
        showSnackbar(driverFormResponseMessages.driverFormSuccess);
        setFormState({
          name: '',
          email: '',
          phone: '',
          positionOfInterest: '',
          message: '',
        });
        setValidated(false);
      } else {
        showSnackbar(driverFormResponseMessages.driverFormFailure);
      }
    } catch (err) {
      showSnackbar(driverFormResponseMessages.driverFormFailure);
    } finally {
      setSendEmailLoading(false);
    }
  };
  return (
    <div>
      <div className='form-container'>
        <div className='driver-form'>
          <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
            <Container>
              <h2>{strings.inquiryHeading}</h2>
              <Row className='justify-content-md-center'>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'name'}
                    label='Name'
                    handleChange={handleChange}
                    value={formState.name}
                    required
                    errorMessage={`Please enter the first name`}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'email'}
                    label='Email'
                    handleChange={handleChange}
                    value={formState.email}
                    required
                    errorMessage={
                      formState.email
                        ? 'Please enter a valid email address'
                        : 'Please enter an email address'
                    }
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'phone'}
                    label='Phone'
                    required
                    value={formState.phone}
                    handleChange={handleChange}
                    errorMessage={'Please enter the phone'}
                  />
                </Col>
                <Col lg={6} md={12}>
                  <FormInput
                    name={'positionOfInterest'}
                    label='Position of Interest'
                    value={formState.positionOfInterest}
                    handleChange={handleChange}
                    required
                    errorMessage={`Please enter the Position of Interest`}
                  />
                </Col>
                <Col lg={12} md={12}>
                  <FormInput
                    name={'message'}
                    label='Message Here (optional)'
                    handleChange={handleChange}
                    value={formState.message}
                  />
                </Col>
              </Row>
              <div className='button-container'>
                <Button
                  disabled={sendEmailLoading}
                  id='contact-form-button'
                  type='submit'
                >
                  {sendEmailLoading ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            </Container>
          </Form>
        </div>
      </div>
      <div className='mt-5 toast-parent'>
        <Toast
          onClose={() => setToast({ show: false, message: '' })}
          show={toast.show}
          delay={3000}
          autohide
        >
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default DriveForUsForm;
