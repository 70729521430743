export const formatAmount = (amount) => {
  if (amount == null || isNaN(amount)) return null;
  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const parseFloatWithTrim = (item) => {
  if (
    item.rate &&
    item.maxRate &&
    parseFloat(item.maxRate) > parseFloat(item.rate)
  ) {
    return `${formatAmount(item.rate)} - ${formatAmount(item.maxRate)}`;
  }
  return item.rate ? `${formatAmount(item.rate)}` : '';
};

export const formatContactEmail = (data) => {
  let htmlString =
    '<div><p>New contact form submitted from <a href="http://localhost:3000/contact">http://localhost:3000/contact</a></p>'; // need to update the url here
  htmlString = `${htmlString}<p>First Name: ${data.firstName}</p><p>Last Name: ${data.lastName}</p>`;
  htmlString = `${htmlString}<p>Email: ${data.emailAddress}</p>`;
  htmlString = `${htmlString}<p>Phone: ${data.phone}</p>`;
  if (data.state.length > 0) {
    htmlString = `${htmlString}<p>State: ${data.state}</p>`;
  }
  if (data.city.length > 0) {
    htmlString = `${htmlString}<p>City: ${data.city}</p>`;
  }
  if (data.zip.length > 0) {
    htmlString = `${htmlString}<p>Zip: ${data.zip}</p>`;
  }
  htmlString = `${htmlString}<p>Description: ${data.description}</p></div>`;
  return htmlString;
};

export const formatDriverEmail = (data) => {
  let htmlString = `<p><strong>Name:</strong> ${data.name}</p>`;
  htmlString = `${htmlString}<p><strong>Email:</strong> ${data.email}</p>`;
  htmlString = `${htmlString}<p><strong>Phone:</strong> ${data.phone}</p>`;
  htmlString = `${htmlString}<p><strong>Position of Interest:</strong> ${data.positionOfInterest}</p>`;
  if (data.message.length > 0) {
    htmlString = `${htmlString}<p><strong>Message:</strong> ${data.message}</p>`;
  }
  return htmlString;
};
