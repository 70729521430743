import { Card } from 'react-bootstrap';
import './serviceCard.scss';
const FeatureCards = ({ img, tagTitle}) => {
    return (
        <Card className='card-wrap'>
            <Card.Body className='p-0'>
                <div className='img-container'>
                    <img src={img} width={100} height={100} alt=''/>
                </div>
                <div className='tag-title'><p>{tagTitle}</p></div>
            </Card.Body>
        </Card>
    )
}

export default FeatureCards;