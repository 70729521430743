import React, { useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import images from '../../assets/images';
import DriveForUsForm from './components/driverForUsForm';
import './DriveForUs.scss';
import { HeaderContext } from '../../context/headerContext';

const DriveForUsPage = () => {
  const { toggle } = useContext(HeaderContext);

  return (
    <div style={{ minHeight: '80vh' }}>
      <Container fluid>
        <Row className='remove-padding'>
          <Col md={12} lg={6} className='remove-padding image-wrapper'>
            <img
              src={images.truckSide}
              width={'100%'}
              height={'100%'}
              alt='sideTruck'
            />
          </Col>
          <Col md={12} lg={6} className='remove-padding'>
            <div className='full-width bg-primary1'></div>
          </Col>
        </Row>
        <div
          className={`${toggle ? 'form-padding' : ''} driver-form-container`}
        >
          <DriveForUsForm />
        </div>
      </Container>
    </div>
  );
};

export default DriveForUsPage;
