import images from '../assets/images';
import { strings } from './constants';
export const trucksQuoteData = [
  {
    id: 1,
    text: strings.flatbed,
    icon: images.flatbed,
    value: 'flatbed',
  },
  {
    id: 2,
    text: strings.rgn,
    icon: images.rgn,
    value: 'rgn',
  },
  {
    id: 3,
    text: strings.powerOnly,
    icon: images.powerOnly,
    value: 'powerOnly',
  },
];

export const workTypeCard = [
  {
    id: 1,
    title: strings.flatBedsTitle,
    description: strings.flatBedsDescription,
    cardImg: images.flatbeds,
  },
  {
    id: 2,
    title: strings.ourFleetTitle,
    description: strings.ourFleetDescription,
    cardImg: images.fleet,
  },
  {
    id: 3,
    title: strings.overSizeLoadTitle,
    description: strings.overSizeLoadDescription,
    cardImg: images.fleet,
  },
  {
    id: 4,
    title: strings.ourTeamTitle,
    description: strings.ourTeamDescription,
    cardImg: images.teamImage,
  },
];
export const serviceCard = [
  {
    id: 1,
    img: images.multipleReefers,
    tagTitle: strings.officeService,
  },
  {
    id: 2,
    img: images.dryVanMountains,
    tagTitle: strings.cdlService,
  },
  {
    id: 3,
    img: images.reeferInMountains,
    tagTitle: strings.maintenanceService,
  },
];
