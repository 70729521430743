import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InstantQuotePage from './pages/InstantQuote/InstantQuotePage';
import DriveForUsPage from './pages/DriveForUs/DriveForUsPage';
import AboutUsPage from './pages/AboutUs/AboutUsPage';
import ContactUsPage from './pages/ContactUs/ContactUsPage';
import { routeNames } from './utils/constants';

const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<InstantQuotePage />} />
      <Route
        path={routeNames.instantQuotePage}
        element={<InstantQuotePage />}
      />
      <Route path={routeNames.driveForUs} element={<DriveForUsPage />} />
      <Route path={routeNames.aboutUs} element={<AboutUsPage />} />
      <Route path={routeNames.contact} element={<ContactUsPage />} />
      <Route path={routeNames.driveForUs} element={<DriveForUsPage />} />
    </Routes>
  );
};

export default AppRouter;
