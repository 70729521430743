import React from 'react';
const Typography = ({ title, color = '#FFFEFE', size, weight, mt, pt }) => {
  return (
    <div
      style={{
        color,
        fontSize: size,
        fontWeight: weight,
        marginTop: mt,
        paddingTop: pt,
      }}
    >
      {title}
    </div>
  );
};
export default Typography;
