import React, { useEffect } from 'react';

const GoogleMapsApi = (props) => {
  // convert distance to miles
  const convertToMiles = (distanceString) => {
    if (distanceString.includes('ft')) {
      return (
        parseFloat(distanceString.replace(/[, ft]+/g, '')) * 0.000189394
      ).toFixed(2);
    } else if (distanceString.includes('km')) {
      return (
        parseFloat(distanceString.replace(/[, km]+/g, '')) * 0.621371
      ).toFixed(2);
    } else if (distanceString.includes('mi')) {
      return distanceString.replace(/[, mi]+/g, '');
    } else if (distanceString.includes('m')) {
      return (
        parseFloat(distanceString.replace(/[, m]+/g, '')) * 0.000621371
      ).toFixed(2);
    }
  };
  const {
    origin,
    destination,
    onDistanceChange,
    setPickupLocation,
    setDeliveryLocation,
    distanceLoading,
  } = props;

  const getAddressData = (status, results) => {
    if (status === 'OK' && results.length > 0) {
      const addressData = {};
      for (const component of results[0].address_components) {
        const types = component.types;
        if (types.includes('postal_code')) {
          addressData.zipCode = component.short_name;
        } else if (types.includes('locality')) {
          addressData.city = component.long_name;
        } else if (!addressData.city && types.includes('neighborhood')) {
          addressData.city = component.long_name;
        } else if (types.includes('administrative_area_level_1')) {
          addressData.state = component.short_name;
        } else if (types.includes('formatted_address')) {
          addressData.address = component.formatted_address;
        }
      }
      return addressData;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (window.google?.maps) {
      if (origin && destination) {
        distanceLoading(true);
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin,
            destination,
            travelMode: 'DRIVING',
          },
          (response, status) => {
            if (status === 'OK') {
              const leg = response.routes[0].legs[0];
              const distanceString = leg.distance.text;
              const distance = convertToMiles(distanceString);
              onDistanceChange(distance);
            } else {
              console.error('Error fetching directions:', status);
            }
            distanceLoading(false);
          }
        );
      }
    }
  }, [origin, destination, onDistanceChange, distanceLoading]);
  useEffect(() => {
    if (window.google?.maps) {
      const geocoder = new window.google.maps.Geocoder();
      // Geocode the address
      if (origin) {
        geocoder.geocode({ address: `${origin}` }, (results, status) => {
          const addressData = getAddressData(status, results);
          if (addressData) {
            setPickupLocation(addressData);
          }
        });
      }
      if (destination) {
        geocoder.geocode({ address: `${destination}` }, (results, status) => {
          const addressData = getAddressData(status, results);
          if (addressData) {
            setDeliveryLocation(addressData);
          }
        });
      }
    }
  }, [
    origin,
    destination,
    setDeliveryLocation,
    setPickupLocation,
    distanceLoading,
  ]);

  return <></>;
};

export default GoogleMapsApi;
