import React from 'react';
import { Container } from 'react-bootstrap';
import './InstantQuote.scss';
import GetQuoteForm from '../../components/forms/GetQuoteForm';
import BgGradientContainer from '../../components/bgGradientContainer';
import { strings } from '../../utils/constants';

const InstantQuotePage = () => {
  return (
    <div>
      <BgGradientContainer style={{ paddingBottom: '0rem' }}>
        <Container fluid className='instant-container p-0'>
          <div className='text-center'>
            <h1>Building Relationships One Load At A Time</h1>
            <p>Built since 2001</p>
          </div>
          <GetQuoteForm />
        </Container>

        <Container fluid className='instant-footer'>
          <p>{strings.instantQuoteDescription}</p>
        </Container>
      </BgGradientContainer>
    </div>
  );
};

export default InstantQuotePage;
