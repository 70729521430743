// Routes
export const email = 'dispatcher@larosstrucking.com';
export const routeNames = {
  homepage: '/',
  instantQuotePage: '/instant-quote',
  driveForUs: '/drive-for-us',
  aboutUs: '/about-us',
  contact: '/contact',
};
export const strings = {
  instantQuotePage: 'Instant Quote',
  instantQuoteDescription:
    'The work we do at LA Ross Trucking is driven by deep customer partnerships and defined by a relentless focus on safety, operational performance, and responsible growth. Our mission is to deliver nothing less than excellence in every aspect of our business and to build value for our customers and employees. We strive to share the prosperity of the company with our employees and their families. Positivity is the key to our success as a safe, and enjoyable workplace. We are committed to handle all situations with an attitude of optimism.',
  driveForUs: 'Drive For Us',
  gallery: 'Gallery',
  aboutUs: 'About Us',
  contact: 'Contact Us',
  flatbed: 'Flatbed',
  powerOnly: 'Power Only',
  rgn: 'RGN',
  aboutName: `ABOUT`,
  aboutTitle: `It's not just our family, it  is yours too.`,
  aboutDescription: `LA Ross Trucking is dedicated to providing excellent customer
  service. We strive for our customers to prosper through a good
  working relationship, and through professional delivery of freight
  or equipment. LA Ross Trucking values our employees. We are first
  and foremost, a family company. Family plays a central role in our
  company and is the heart and soul of our mission. The members of
  Upper Management at LA Ross are dedicated to the prosperity of the
  employees and their families. We ask all employees to contribute to
  the collective attitude of positivity that we foster at LA Ross
  Trucking.`,
  flatBedsTitle: `FLAT-BEDS`,
  flatBedsDescription: `From pipe to lumber to steel and more! If you need it we'll haul it. Get your quote today!`,
  ourFleetTitle: `OUR FLEET`,
  ourFleetDescription: `At LA Ross Trucking we take pride in our fleet. With flatbeds, log trailers, and RGN. We can get the job done!`,
  overSizeLoadTitle: `OVER-SIZE LOADS`,
  overSizeLoadDescription: `Overweight? Over Height? Over Width? Supersized? We haul them all. Get a quote today!`,
  ourTeamTitle: `OUR TEAM`,
  ourTeamDescription: `LA Ross Trucking has been family owned and operated since 2001. We  strive to build relationships one load at a time.`,
  officeService: `Office`,
  cdlService: `CDL`,
  maintenanceService: `Maintenance`,
  inquiryHeading: `Join Our Team!`,
};
export const GoogleAutoCompleteOptions = {
  API_KEY: 'AIzaSyD3Srz6dpiK1Pl6CcC9r5ZfVQ9XXCJ6sWs',
  fields: ['geometry', 'formatted_address', 'address_components'],
  countries: ['us', 'ca'],
};
export const contactResponseMessages = {
  contactFormSuccess: 'Your request has been submitted.',
  contactFormFailure: 'Something went wrong, please try again later.',
};
export const apiRoutes = {
  sendEmail: 'email/public/send',
  searchAvailableTrucks: 'marketplace/lanes/search',
};

export const driverFormResponseMessages = {
  driverFormSuccess: 'Your request has been submitted.',
  driverFormFailure: 'Something went wrong, please try again later.',
};

export const contactUsFormInitialState = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phone: '',
  state: '',
  city: '',
  zip: '',
  description: '',
};
