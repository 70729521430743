import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUs.scss';
import WorkTypeCard from '../../components/cards/workTypeCard/WorkTypeCard';
import { workTypeCard, serviceCard } from '../../utils/data';
import ServiceCard from '../../components/cards/serviceCard/ServiceCard';
import images from '../../assets/images';
import { strings } from '../../utils/constants';

const AboutUsPage = () => {
  return (
    <div>
      <Container fluid className='p-0'>
        <section className='showcase'>
          <img src={images.backgroundTruckImage} alt='' />
          <div className='overlay'>
            <span>{strings.aboutName}</span>
            <h2>{strings.aboutTitle}</h2>
            <p>{strings.aboutDescription}</p>
          </div>
        </section>
      </Container>
      <Container fluid className='p-0 overflow-hidden'>
        <Row>
          {serviceCard.map((card) => (
            <Col key={card.id} className='p-0' col={12} lg={4}>
              <ServiceCard img={card.img} tagTitle={card.tagTitle} />
            </Col>
          ))}
        </Row>
      </Container>
      <Container>
        <Row className='justify-content-center work-type-section'>
          <h5 className='section-service'>SERVICES</h5>
          <h2 className='section-work-type'>Types of work we do</h2>
          {workTypeCard.map((card) => (
            <Col
              key={card.id}
              className='vehicle-container mb-4'
              col={12}
              lg={6}
            >
              <WorkTypeCard
                title={card.title}
                description={card.description}
                cardImg={card.cardImg}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default AboutUsPage;
