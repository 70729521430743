import React from 'react';
import './workTypeCard.scss';
import { Card } from 'react-bootstrap';

const WorkTypeCard = ({ title, description, cardImg }) => {
  return (
    <Card className='card-wrap'>
      <Card.Body>
        <Card.Img variant='top' src={cardImg} />
        <div className='content-wrap'>
          <Card.Title>{title}</Card.Title>
          <Card.Text>{description}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default WorkTypeCard;
