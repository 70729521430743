import React from 'react';

import ContactUsForm from './components/form';
import BgGradientContainer from '../../components/bgGradientContainer';
import './ContactUs.scss';

const ContactUsPage = () => {
  return (
    <BgGradientContainer>
      <div className='contact-form-container'>
        <h1>Contact Us</h1>
        <p>
          To be contacted by a L.A Ross Trucking representative, please provide
          the following:
        </p>
      </div>

      <ContactUsForm />
    </BgGradientContainer>
  );
};

export default ContactUsPage;
