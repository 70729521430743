import React, { createContext, useEffect, useState } from 'react';

const HeaderContext = createContext();

let getResponsiveToggle;
const HeaderProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false);
  const [isResponsiveToggle, setIsResponsiveToggle] = useState(false);
  getResponsiveToggle = isResponsiveToggle;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setToggle(false);
      } else {
        if (getResponsiveToggle === true && toggle === false) {
          setToggle(true);
        }
      }
    };

    // Set initial toggle state based on window width
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toggle, isResponsiveToggle]);

  const updateToggle = () => {
    setToggle((prevToggle) => !prevToggle);
    setIsResponsiveToggle((prevToggle) => !prevToggle);
  };

  return (
    <HeaderContext.Provider value={{ toggle, updateToggle }}>
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
