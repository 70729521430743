import React from 'react';
import images from '../../assets/images';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { email, routeNames, strings } from '../../utils/constants';
const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div>
      <footer className='footer-wrapper font-dm-sans'>
        <Container className='pt-5'>
          <Row>
            <Col lg={5} xs={12}>
              <div className='text-start'>
                <div>
                  <img
                    src={images.logo}
                    width='176'
                    height='176'
                    className='d-inline-block align-center'
                    alt='LA ROSS logo'
                  />
                </div>
              </div>
            </Col>
            <Col lg={7} xs={12}>
              <Row>
                <Col lg={4} xs={12}>
                  <div className='footer-list'>
                    <ul type='none'>
                      <li>About</li>
                      <li>
                        <Link
                          to={routeNames.instantQuotePage}
                          onClick={scrollToTop}
                        >
                          {strings.instantQuotePage}
                        </Link>
                      </li>
                      <li>
                        <Link to={routeNames.contact} onClick={scrollToTop}>
                          {strings.contact}
                        </Link>
                      </li>
                      <li>
                        <Link to={routeNames.driveForUs} onClick={scrollToTop}>
                          {strings.driveForUs}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col lg={6} xs={12}>
                  <div className='footer-list'>
                    <ul type='none'>
                      <li>Contact</li>
                      <li>
                        <a
                          href='https://maps.app.goo.gl/i5yFsFX9ysEuNkfy5'
                          target='_blank'
                          rel='noreferrer'
                        >
                          PO BOX 668, Titusville, PA, United States, 16354
                        </a>
                      </li>
                      <li>
                        <a
                          href={`mailto:${email}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          {`Email: ${email}`}
                        </a>
                      </li>
                      <li>
                        <a
                          href='tel:8147073122'
                          target='_blank'
                          rel='noreferrer'
                        >
                          Phone: 814-707-3122
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className='divider'></div>
          <Row className='py-4 footer-bottom'>
            <Col
              lg={12}
              className='d-flex align-content-md-center justify-content-start'
            >
              <p className='mb-0'>
                © 2024 L.A Ross Trucking. All rights reserved
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};
export default Footer;
