import React, { forwardRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GoogleAutoCompleteOptions } from '../../utils/constants';
import { Form } from 'react-bootstrap';

const GoogleAutoCompleted = forwardRef((props, ref) => {
  const {
    placeholder,
    required,
    onPlaceSelected,
    setPickupValue,
    onInput,
    value,
    displayAddress,
  } = props;

  const handlePlaceSelected = (place) => {
    if (place && place.formatted_address) {
      const { city, state } = extractCityAndState(place);
      const formattedPlace = `${city}, ${state}`;
      if (onPlaceSelected) {
        onPlaceSelected(place, formattedPlace, place.formatted_address);
      }
      setPickupValue(formattedPlace);
    }
  };

  const { ref: placesRef } = usePlacesWidget({
    apiKey: GoogleAutoCompleteOptions.API_KEY,
    onPlaceSelected: handlePlaceSelected,
    options: {
      fields: GoogleAutoCompleteOptions.fields,
      componentRestrictions: {
        country: GoogleAutoCompleteOptions.countries,
      },
      types: ['geocode'],
    },
  });

  const extractCityAndState = (place) => {
    let city = '';
    let state = '';

    for (const component of place.address_components) {
      if (component.types.includes('locality')) {
        city = component.long_name;
      } else if (!city && component.types.includes('neighborhood')) {
        city = component.long_name;
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name;
      }
    }
    return { city, state };
  };

  const handleInputChange = (e) => {
    setPickupValue(e.target.value);
  };

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      placesRef.current.focus();
    },
  }));

  return (
    <Form.Control
      placeholder={placeholder}
      size='sm'
      ref={placesRef}
      required={required}
      value={displayAddress ? displayAddress : value}
      onChange={handleInputChange}
      onInput={onInput}
      className='w-100'
    />
  );
});

export default GoogleAutoCompleted;
