import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './formInput.scss';

const FormInput = ({
  label,
  required = false,
  name,
  optional,
  handleChange,
  type = 'text',
  errorMessage,
  value,
}) => {
  return (
    <Form.Group className='mb-2 form-group-container' controlId='custom-input'>
      <Form.Label id='form-label'>
        {label} {required ? '*' : optional ? '(optional)' : ''}
      </Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          name={name}
          type={type}
          value={value}
          onChange={(e) => handleChange(e.target.value, name)}
          required={required}
        />
        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};
export default FormInput;
