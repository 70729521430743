import React, { useContext } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { routeNames, strings } from '../../utils/constants';
import { HeaderContext } from '../../context/headerContext';
import images from '../../assets/images';

const routes = [
  { title: strings.instantQuotePage, path: routeNames.instantQuotePage },
  { title: strings.aboutUs, path: routeNames.aboutUs },
  { title: strings.contact, path: routeNames.contact },
];

const AppNavbar = () => {
  const { updateToggle } = useContext(HeaderContext);
  const location = useLocation();

  return (
    <div>
      <Navbar
        expand='lg'
        collapseOnSelect
        className='header-primary'
        onToggle={updateToggle}
      >
        <Container>
          <Link className='navbar-brand' to={routeNames.instantQuotePage}>
            <img
              src={images.logo}
              width='87'
              height='87'
              className='d-inline-block align-center'
              alt='LA ROSS logo'
            />
          </Link>

          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mx-lg-auto mx-0 my-2 my-lg-0'>
              {routes.map((d) => (
                <NavLink
                  key={d.title}
                  to={d.path}
                  className={({ isActive }) =>
                    isActive ||
                    (d.path === routeNames.instantQuotePage &&
                      location.pathname === '/')
                      ? 'nav-link text-start active'
                      : 'nav-link text-start'
                  }
                >
                  {d.title}
                </NavLink>
              ))}
            </Nav>
            <Link
              to={routeNames.driveForUs}
              type='button'
              className='primary-btn'
            >
              Drive For Us
            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default AppNavbar;
