import { Card } from 'react-bootstrap';

const VehicleCard = ({ icon, text, isActive }) => {
  return (
    <Card
      className='d-flex justify-center align-center direction-column'
      style={
        isActive
          ? {
              backgroundColor: '#b08e5e',
              border: 'none',
            }
          : {}
      }
    >
      <img src={icon} className='vehicleImage' alt={text} />
      <span className='text-center'>{text}</span>
    </Card>
  );
};
export default VehicleCard;
