import React, { useState } from 'react';
import { Form, Row, Col, Toast } from 'react-bootstrap';
import '../../pages/InstantQuote/InstantQuote.scss';
import { trucksQuoteData } from '../../utils/data';
import VehicleCard from '../cards/VehicleCard';
import GoogleAutoCompleted from '../GoogleAutocomplete';
import { Controller, useForm } from 'react-hook-form';
import DatePickerField from './DatePickerField';
import GoogleMapsApi from '../GoogleMapsApi';
import { searchAvailableTrucks } from '../../services/apis';
import { parseFloatWithTrim } from '../../utils/helpers';
import RateLoader from '../../Loader/RateLoader';
import Typography from '../typography';

const GetQuoteForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [pickupLocation, setPickupLocation] = useState({});
  const [deliveryLocation, setDeliveryLocation] = useState({});
  const [pickupDisplayAddress, setPickupDisplayAddress] = useState('');
  const [deliveryDisplayAddress, setDeliveryDisplayAddress] = useState('');
  const [pickupAddress, setPickupAddress] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [pickupLatLng, setPickupLatLng] = useState({});
  const [deliveryLatLng, setDeliveryLatLng] = useState({});
  const [distance, setDistance] = useState(null);
  const [activeType, setActiveType] = useState('');
  const [distanceLoading, setDistanceLoading] = useState(false);
  const [availableTrucksState, setAvailableTrucksState] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    message: '',
  });

  const handleGoogleOnInput = (e, field) => {
    setAvailableTrucksState(null);
    field.onChange(e);
  };

  const handleFormSubmit = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    const originAddress = updateAddress(pickupLocation, pickupLatLng);
    const destinationAddress = updateAddress(deliveryLocation, deliveryLatLng);

    const pickUpDate = data.pickUpDate.split(' to ');

    try {
      const payloadData = {
        startDate: pickUpDate[0],
        endDate: pickUpDate[1] || pickUpDate[0],
        destination: JSON.stringify(destinationAddress),
        origin: JSON.stringify(originAddress),
        distance: distance ? Number(distance) : 0,
        carrierCompanyId: 267,
        page: 1,
        pageSize: 5,
      };
      if (data.weight) {
        payloadData.weight = data.weight;
      }
      if (activeType) {
        payloadData['equipmentTypes[]'] = activeType;
      }

      const { payload } = await searchAvailableTrucks(payloadData);

      if (payload?.lanes?.length > 0) {
        setAvailableTrucksState(payload);
      } else {
        setAvailableTrucksState(null);
        setToast({ show: true, message: 'No Trucks Found!' });
      }
    } catch (error) {
      setToast({
        show: true,
        message: 'Contact us to get a quote!',
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const updateAddress = (location, locationLatLng) => {
    return {
      city: location.city,
      state: location.state,
      zipCode: location.zipCode,
      latitude: locationLatLng.lat,
      longitude: locationLatLng.lng,
    };
  };

  const checkKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div className='get-quote-form'>
      <Form
        onSubmit={handleSubmit(handleFormSubmit)}
        onKeyDown={(e) => checkKeyDown(e)}
      >
        <Row className='mb-4'>
          <Col xs={12} lg={5}>
            <p className='mb-2'>Pickup City State *</p>
            <Controller
              name='pickup'
              control={control}
              defaultValue=''
              rules={{ required: 'Pickup location is required' }}
              render={({ field }) => {
                return (
                  <div>
                    <GoogleAutoCompleted
                      displayAddress={pickupDisplayAddress}
                      onPlaceSelected={(place) => {
                        if (place.geometry) {
                          const { location } = place.geometry;
                          setPickupAddress(place.formatted_address);
                          setPickupDisplayAddress(place.formatted_address);

                          setPickupLatLng({
                            lat: location.lat(),
                            lng: location.lng(),
                          });
                        }
                        field.onChange(place.formatted_address);
                      }}
                      setPickupValue={(data) => {
                        setPickupDisplayAddress(data);
                      }}
                      placeholder='Pickup city state'
                      {...field}
                      onInput={(e) => handleGoogleOnInput(e, field)}
                    />

                    {errors?.pickup?.message && (
                      <div style={{ height: '30px' }}>
                        <p className='text-danger fs-6'>
                          {errors?.pickup?.message}
                        </p>
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </Col>

          <Col xs={12} lg={2} className='mt-md-5'>
            <div className='joint text-center'> To </div>
          </Col>

          <Col xs={12} lg={5}>
            <p className='mb-2'>Delivery City State *</p>
            <Controller
              name='delivery'
              control={control}
              defaultValue=''
              rules={{ required: 'Delivery location is required' }}
              render={({ field }) => {
                return (
                  <div>
                    <GoogleAutoCompleted
                      displayAddress={deliveryDisplayAddress}
                      onPlaceSelected={(place) => {
                        if (place.geometry) {
                          const { location } = place.geometry;
                          setDeliveryAddress(place.formatted_address);
                          setDeliveryDisplayAddress(place.formatted_address);
                          setDeliveryLatLng({
                            lat: location.lat(),
                            lng: location.lng(),
                          });
                        }
                        field.onChange(place.formatted_address);
                      }}
                      setPickupValue={(data) => {
                        setDeliveryDisplayAddress(data);
                      }}
                      placeholder='Delivery city state'
                      {...field}
                      onInput={(e) => handleGoogleOnInput(e, field)}
                    />
                    {errors?.delivery?.message && (
                      <div style={{ height: '30px' }}>
                        <p className='text-danger fs-6'>
                          {errors?.delivery?.message}
                        </p>
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </Col>
        </Row>

        <Row className='align-center'>
          <Col xs={12} lg={5}>
            <p className='mb-2'>Pickup Date Range *</p>
            <Controller
              name='pickUpDate'
              control={control}
              defaultValue={''}
              rules={{
                required: 'Pickup date is required',
              }}
              render={({ field: { value, onChange, onClose } }) => {
                return (
                  <DatePickerField
                    field={value}
                    value={value}
                    onChange={onChange}
                    onClose={onClose}
                    mode={'range'}
                    placeholder={'Select a date range'}
                  />
                );
              }}
            />
            {errors.pickUpDate && (
              <div style={{ height: '30px' }}>
                <p className='text-danger fs-6'>{errors.pickUpDate.message}</p>
              </div>
            )}
          </Col>
          <Col xs={12} lg={2}></Col>
          <Col xs={12} lg={5}>
            <p className='mb-2 mt-sml-4'>Weight</p>
            <Controller
              name='weight'
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <Form.Control
                  type='number'
                  placeholder='Enter Weight'
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <div>
          <p className='mb-2 mt-4'>Equipment Type</p>
          <Row>
            {trucksQuoteData.map((truck) => (
              <Col
                col={4}
                key={truck.id}
                className='mb-1'
                onClick={() =>
                  setActiveType(truck.value === activeType ? '' : truck.value)
                }
              >
                <VehicleCard
                  icon={truck.icon}
                  text={truck.text}
                  isActive={activeType === truck.value}
                />
              </Col>
            ))}
          </Row>
        </div>
        <Row className='mt-4'>
          <Col md={6} className='mb-1'>
            {loading && <RateLoader />}
            {availableTrucksState?.lanes?.length && (
              <div>
                <Typography
                  title={'Rate'}
                  color={'#fff'}
                  weight={'600'}
                  size={'18px'}
                />
                <Typography
                  title={parseFloatWithTrim(availableTrucksState?.lanes[0])}
                  color={'var(--secondary)'}
                  weight={'600'}
                  size={'25px'}
                />
              </div>
            )}
          </Col>
          <Col md={6}>
            <div className='btn-alignment'>
              <button className='search-btn' type='submit'>
                Search
              </button>
            </div>
          </Col>
        </Row>

        <GoogleMapsApi
          origin={pickupAddress}
          destination={deliveryAddress}
          onDistanceChange={setDistance}
          setPickupLocation={setPickupLocation}
          setDeliveryLocation={setDeliveryLocation}
          distanceLoading={setDistanceLoading}
          distanceLoadingFlag={distanceLoading}
        />
      </Form>
      <div className='toast-parent'>
        <Toast
          onClose={() => setToast({ show: false, message: '' })}
          show={toast.show}
          delay={3000}
          autohide
        >
          <Toast.Body>{toast.message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default GetQuoteForm;
